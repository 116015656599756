import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";
import ContactForm from "../components/contact-form";
import { Container, Box, useTheme, Theme } from "@material-ui/core";
import Section from "../components/section";
import { RoundPaper } from "../components/styledComponents";
import Hero from "../components/hero";
import styled from "@emotion/styled";
import MainLayout from "../layouts/mainLayout";

interface LocationProps {
    location: Location;
}

const NegSection = styled(Section)`
    margin-top: -185px;
    z-index: 2;
    position:relative;
`;

const ContactPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();

    const data = useStaticQuery(graphql`
    query {
      seo: contactJson(block: {eq: "seo" }) {
        title
        description
        image {
            childImageSharp {
                resize(width: 750, height: 300, cropFocus: ENTROPY) {
                    src
                    width
                    height
                }
            }
        }
      }
      hero: contactJson(block: {eq: "hero"}) {
        title
        tagline
        ctaText
        ctaUrl
        cta2Text
        cta2Url
        image {
            childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
      contact: contactJson(block: {eq: "contact"}) {
          title
          cta
      }
    }`);

    return (
        <MainLayout transparentNav>
            <SEO {...data.seo} location={props.location} />
            <Hero heroHeight={55} overlayColor={theme.palette.primary.dark} {...data.hero} />
            <NegSection>
                <Container maxWidth="sm">
                    <RoundPaper elevation={6}>
                        <Box py={4}>
                            <ContactForm title={data.contact.title} cta={data.contact.cta} />
                        </Box>
                    </RoundPaper>
                </Container>
            </NegSection>
        </MainLayout>
    )
}

export default ContactPage